// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageOrder__error {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

.pageOrder__error_Content {

    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/modules/PageError/Error.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".pageOrder__error {\n    display: flex;\n    min-height: 100vh;\n    justify-content: center;\n    align-items: center;\n}\n\n.pageOrder__error_Content {\n\n    display: flex;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
