// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Block-Modal {
  position: fixed;
  background: rgba(101, 94, 94, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.Block-Modal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #fff;
  border-radius: 20px 20px 0 0;
  bottom: 0px;
  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);
  left: 0;
  padding: 9px 16px 16px;
  position: fixed;
  right: 0;
  z-index: 2;
  overflow-y: scroll;
  text-align: center;
  max-height: 75%;
}

.Block-Modal__header {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 16px;
  text-align: center;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;EAClC,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,UAAU;AACZ;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,gBAAgB;EAChB,4BAA4B;EAC5B,WAAW;EACX,0CAA0C;EAC1C,OAAO;EACP,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".Block-Modal {\n  position: fixed;\n  background: rgba(101, 94, 94, 0.5);\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 2;\n}\n.Block-Modal__Content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  background: #fff;\n  border-radius: 20px 20px 0 0;\n  bottom: 0px;\n  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);\n  left: 0;\n  padding: 9px 16px 16px;\n  position: fixed;\n  right: 0;\n  z-index: 2;\n  overflow-y: scroll;\n  text-align: center;\n  max-height: 75%;\n}\n\n.Block-Modal__header {\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 20px;\n  margin-bottom: 16px;\n  text-align: center;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
