// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.check__input {

  position: absolute;
  appearance: none;

}

.check_box {

  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 14px;
  min-width: 14px;

  margin-right: 1.2em;
  border-radius: 0.25em;
  border: 1.5px solid #dcdee3;
  background-color: white;

}

.check_box::before {

  content: "";
  position: absolute;
  width: 5px;
  height: 10px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  border-color: #413dff;
  display: block;
  left: 3px;
  opacity: 0;
  transition: .2s;

}
.check__input:checked+.check_box::before {

  opacity: 1;
}


`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/components/BLOCK_СONDITIONSORDER/%D0%A1onditionsOrder.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;EAGlB,gBAAgB;;AAElB;;AAEA;;EAEE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,eAAe;;EAEf,mBAAmB;EACnB,qBAAqB;EACrB,2BAA2B;EAC3B,uBAAuB;;AAEzB;;AAEA;;EAEE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;EACxB,qBAAqB;EACrB,cAAc;EACd,SAAS;EACT,UAAU;EACV,eAAe;;AAEjB;AACA;;EAEE,UAAU;AACZ","sourcesContent":["\n.check__input {\n\n  position: absolute;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n\n}\n\n.check_box {\n\n  display: inline-block;\n  position: relative;\n  cursor: pointer;\n  height: 14px;\n  min-width: 14px;\n\n  margin-right: 1.2em;\n  border-radius: 0.25em;\n  border: 1.5px solid #dcdee3;\n  background-color: white;\n\n}\n\n.check_box::before {\n\n  content: \"\";\n  position: absolute;\n  width: 5px;\n  height: 10px;\n  border-style: solid;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n  border-color: #413dff;\n  display: block;\n  left: 3px;\n  opacity: 0;\n  transition: .2s;\n\n}\n.check__input:checked+.check_box::before {\n\n  opacity: 1;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
