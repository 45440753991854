export const WAYS_OBJECT_PAY = {
  BANK_CARD: [
    require("@assets/waysPay/card.svg").default,
    "Банковская карта (Россия)",
  ],
  SBP: [require("@assets/waysPay/SFP.svg").default, "Система быстрых платежей"],
  YANDEX_PAY: [
    require("@assets/waysPay/yandex-pay.svg").default,
    "Яндекс оплата",
  ],
  SBER: [null, "Sber"],
  PAYANYWAY: [null, "Банковская карта (Остальной Мир)"],
  DOLYAME: [require("@assets/waysPay/dolyame.svg").default, "Заплатить Долями"],
  // "Ру карта": [null, "Ру карта"],
  POS_CREDIT_TINKOFF: [null, "Кредитование от Тинькофф банк"],
};
