import { getApiUrl } from "@api/getApiUrl";
import { qrLinktsStore } from "@store/index";
import axios from "axios";

//api.giberno.ru/invoice_qr/?client_id=fb1969e9-8fa1-4b40-a9a4-da10a3fd968e&key_form=c5c5f096-15b4-4abd-b770-b7379500501a
const getPaymentLink = async (
  client_id: string,
  key_form: string,
  items: any,
  tip: any,
  service: any,
  getGibernoOrderId: string,
  wayPay: string,
  clientEmail: string
) => {
  const { ChangeisLoadingQr_Link } = qrLinktsStore;
  ChangeisLoadingQr_Link();
  const url = `${getApiUrl()}/payment/`;
  const response = await axios
    .post(
      url,
      {
        orderID: getGibernoOrderId,
        //  TO-DO здесь вставить аргумент
        tipSum: tip,
        service: service,
        PaymentType: wayPay,
        //  TO-DO можно пустоту
        email: clientEmail,
        items: items,
      },
      {
        // Если я меняю эти параметры, то приходит 500
        params: {
          client_id: client_id,
          key_form: key_form,
        },
      }
    )
    // .then((response: any) => {
    //   if (response.status !== 200) {
    //     throw Error("Что пошло не так! Перезагрузите страницу");
    //   }
    //   return {
    //     config: response.config.params,
    //     infoQrLink: response.data,
    //   };
    // })
    .catch((mesError) => {
      alert(`${mesError.message}`);
    });
  // eslint-disable-next-line no-console
  console.log(response);
  return response?.data;
};

export const paymentLinkAPI = {
  getPaymentLink,
};
