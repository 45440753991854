import React, { useState } from "react";

import "./СonditionsOrder.css";
import CustomCheckBox from "@shared/components/CustomCheckBox";
import Modal from "@shared/components/Modal";
import { orderStore } from "@store/index";
import { observer } from "mobx-react-lite";

const BLOCK_СONDITIONSORDER = observer(() => {
  const [isVisModalCondition, setisVisModalCondition] =
    useState<boolean>(false);
  const [isVisModalServiceFeeWarning, setiIsVisModalServiceFeeWarning] =
    useState(false);

  const {
    getIsAgreeConditionPymentsCheckBox,
    ChangeIsAgreeConditionPymentsCheckBox,
    getIsServiceChargeAmount,
    ChangeIsServiceChargeAmount,
    getServiceChargeAmount,
    getIsServiceFee,
    getIsServiceFeeWarning,
    getServiceFeeText,
  } = orderStore;

  if (isVisModalServiceFeeWarning && getIsServiceFeeWarning) {
    return (
      <Modal
        setchangeVisModal={() =>
          setiIsVisModalServiceFeeWarning(
            (isVisModalServiceFeeWarning) => !isVisModalServiceFeeWarning
          )
        }
        titleModal={"СЕРВИСНЫЙ СБОР"}
      >
        <div>
          Оплачивая сервисный сбор. Вы помогаете делать сервис Гиберно ещё лучше
          и покрываете издержки официанту, чтобы он получил полную сумму чая
        </div>
      </Modal>
    );
  }

  if (isVisModalCondition) {
    return (
      <Modal
        setchangeVisModal={() =>
          setisVisModalCondition((isVisModalCondition) => !isVisModalCondition)
        }
        titleModal={
          "УСЛОВИЯ ИСПОЛЬЗОВАНИЯ И ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ Гиберно"
        }
      >
        <div>
          ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ «GIBERNO» (с физическими лицами) 01 июня
          2023 года Общество с ограниченной ответственностью «Гиберно» (ОГРН
          1207700221937, ИНН 9709062853, адрес места нахождения: 101000, г.
          Москва, Лубянский проезд, д.19, стр.1 кв.1) – далее также «Компания»,
          обладающее исключительными правами на Систему «GIBERNO», с одной
          стороны, и «Пользователи» с другой стороны, заключили настоящее
          пользовательское, лицензионное соглашение (далее – «Соглашение») путем
          присоединения к нему в порядке, предусмотренном ст. 428 Гражданского
          кодекса Российской Федерации в момент установки Системы «GIBERNO» на
          свои мобильные устройства, о нижеследующем: 1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
          Система «GIBERNO» (Система) – совокупность программ для ЭВМ, баз
          данных, а также иных объектов интеллектуальной собственности, включая
          совокупность объектов авторского права, используемых в оформлении
          внешнего вида и наполнении содержания Системы, выраженных в форме
          мобильного приложения для операционных систем iOS и Android.
          Функциональное назначение Системы – предоставление Пользователям
          возможности осуществления Сделок с другими Пользователями Системы;
          Пользователи – дееспособные физические лица, применяющие в
          соответствии с Федеральным законом от 27.11.2018 № 422-ФЗ «О
          проведении эксперимента по установлению специального налогового режима
          «Налог на профессиональный доход» специальный налоговый режим «Налог
          на профессиональный доход»; физического лицо, не применяющее
          специальный налоговый режим «Налог на профессиональный доход»,
          обратившийся к Системе с ознакомительной целью; Заказчики –
          организации, заключившие лицензионное соглашение с Компанией с целью
          использования Системы для заключения прямых сделок с Пользователями;
          Сделка – соглашение, достигнутое между Пользователем Системы и
          Заказчиком, устанавливающее гражданские права и обязанности, связанные
          с выполнением Пользователем за вознаграждение ограниченного по времени
          поручения в интересах Заказчика. Условия Сделки определяются
          Пользователем и Заказчиком в Системе и согласовываются с Исполнителем
          без участия Компании; Пользовательский Аккаунт (Аккаунт) –
          совокупность страниц Системы с информацией, принадлежащей одному
          Пользователю, используемых для осуществления взаимодействия с
          Компанией и раскрытия информации о себе Заказчикам; 2. ПРЕДМЕТ
          СОГЛАШЕНИЯ 2.1. Компания предоставляет Пользователю неисключительные
          права использовать Систему в порядке и на условиях, предусмотренных
          настоящим Соглашением на территории всего мира. 2.2. Компания
          предоставляет Пользователю доступ к Системе, ее ресурсам - объем
          исключительных прав, - с учетом, установленного для Пользователя
          рейтинга. 2.3. Неисключительные права предоставляются Пользователям на
          период установки Системы на собственные мобильные устройства (ЭВМ). 3.
          ПОРЯДОК ВЕРИФИКАЦИИ В СИСТЕМЕ 3.1. Установка Системы на мобильном
          устройстве Пользователя осуществляется без необходимости
          предоставления Компании какой-либо информации о Пользователе. 3.2. Для
          использования Системы по целевому назначению Пользователь должен
          пройти процедуру верификации. 3.3. Для верификации в Системе
          Пользователь обязан предоставить следующую достоверную информацию о
          себе: [Имя, Фамилия; Дата рождения; по запросу - Номер мобильного
          телефона и(или) Адрес электронной почты (E-mail)]. 3.4. Пользователь
          для целей верификации и уменьшения объема заполняемой информации,
          предусмотренной п.3.3. Соглашения вправе предоставить согласие
          Компании на импорт информации о Пользователе из профиля социальной
          сети и(или) аккаунта в системе iOS или Android; 3.5. До завершения
          процедуры верификации Пользователь предоставляет Компании согласие на
          обработку своих персональных данных в объеме, предусмотренном
          Политикой обработки персональных данных Пользователей Системы
          «GIBERNO». В случае отказа Пользователя от предоставления согласия на
          обработку персональных данных верификация не осуществляется,
          возможность использования Системы по назначению не предоставляется.
          3.6. Моментом завершения процедуры верификации является осуществление
          Пользователем перехода по ссылке, полученной на указанную им
          электронную почту или ввода в Систему подтверждающего кода,
          направленного на указанный номер мобильного телефона в виде
          СМС-сообщения или в виде Pushуведомления Системы. Совершение
          Пользователем одного из указанных действий являются подтверждением его
          волеизъявления в рамках Соглашения об электронном взаимодействии между
          Пользователем и Компанией. 3.7. Завершив процедуру верификации,
          Пользователю предоставляется Аккаунт и возможность ознакомиться с
          ограниченным функционалом Системы. 3.8. Заказчики могут установить
          дополнительные требования к Пользователям и верифицированным данным о
          Пользователях для целей получения доступа к возможности совершения
          Сделок. 3.9. Пользователь вправе дополнить содержание Аккаунта,
          предоставив Компании следующую достоверную информацию о себе: 3.9.1.
          Информацию о ИНН; 3.9.2. Информацию о поле; 3.9.3. Информацию о
          гражданстве; 3.9.4. Информацию об основном языке, и языках, которыми
          владеет Пользователь; 3.9.5. Информацию о городе, в котором находится
          Пользователь; 3.9.6. Информацию об уровне образования; 3.9.7.
          Информацию об опыте работы; 3.9.8. Информацию о навыках и
          компетенциях; 3.9.9. Информацию о социальных сетях; 3.9.10. Информацию
          о номере СНИЛС; 3.9.11. Информацию о серии и номере паспорта,
          информацию об органе, выдавшем паспорт, коде подразделения, дате его
          выдаче; 3.9.12. Информацию о серии и номере водительского
          удостоверения, дате выдаче, сроке действия, категории; 3.9.13.
          Информацию об адресе места жительства или места пребывания; 3.9.14.
          Информацию о номере банковской карты, сроке ее окончания, CVC-коде,
          имени и фамилии держателя карты; 3.9.15. Информацию о номере текущего
          счета Пользователя; 3.9.16. Фотографию собственного изображения;
          3.9.17. Фотографию паспорта, подтверждающее указанное в Системе
          гражданство; 3.9.18. Видео изображения Пользователя с разворотом
          паспорта; 3.9.19. Фотографию действующей медицинской книжки, выданной
          на имя Пользователя; 3.10. Один Пользователь вправе использовать
          только один Аккаунт в Системе. В случае выявления множества Аккаунтов
          у одного Пользователя, Компания вправе перенести информацию о Сделках
          в один Аккаунт, заблокировав иные выявленные, а также применить
          санкции, предусмотренные Положением о рейтинге. 3.11. Пользователь
          вправе удалить Аккаунт. Удаление Аккаунта возможно посредством
          обращения Пользователя в службу поддержки Компании, либо
          самостоятельно посредством функционала Системы. 4. РОЛЬ КОМПАНИИ ПРИ
          РЕАЛИЗАЦИИ ФУНКЦИОНАЛА СИСТЕМЫ 4.1. Компания обязана: 4.1.1.
          обеспечить надлежащее функционирование Системы, предоставить
          Пользователям, прошедшим верификацию, включая дополнительную
          верификацию, доступ к функционалу Системы по заключению Сделок; 4.1.2.
          предоставить возможность Пользователям разместить в Системе оферту о
          намерении заключить договора о выполнении разового поручения. 4.2.
          Компания вправе: 4.1.1. управлять функционалом Системы Пользователя,
          постав его в зависимость от рейтинга Пользователя, присвоенного ему
          Системой в соответствии с Положением о рейтинге; 4.1.2. изменять
          Систему, включая ее отдельные ресурсы, приостанавливая или прекращая
          их действие; 4.1.3. направлять Пользователям Системы рассылку и/или
          push-уведомления рекламно-информационного характера, на что
          Пользователи дают свое согласия в момент установки Системы; 4.1.4.
          прекращать доступ Пользователей к Системе на период проведения
          профилактических работ Компанией или подрядчиками Компании без
          возмещения каких-либо убытков; 4.1.5. запрашивать повторную
          верификацию Пользователей в случае выявления совпадения информации в
          разных Аккаунтах; 4.1.6. объединить Аккаунты Пользователей в один в
          случае выявления совпадения персональной информации; 4.1.7. ограничить
          доступ к ресурсам Системы по совершению Сделок, если Пользователь
          нарушил условия Соглашения; 4.1.8. приостанавливать или прекращать
          доступ Пользователя к Системе или отдельным ее компонентам в случае
          нарушения настоящего Соглашения или понижения рейтинга на условиях
          Положения о рейтинге. 5. РОЛЬ ПОЛЬЗОВАТЕЛЯ В СИСТЕМЕ 5.1. Пользователь
          обязан: 5.1.1. соблюдать законодательство Российской Федерации при
          использовании Системы; 5.1.2. действовать добросовестно организации
          Сделок посредством Системы; 5.1.3. не использовать Систему для обмана
          Заказчиков, совершения преступных деяний, а также иных действий,
          которые могут противоречить интересам Заказчиков и целям Системы;
          5.1.4. оплачивать налоги и сборы, в связи с полученным доходом от
          Сделок; 5.1.5. обращаться к партнерам Компании в целях реализации
          частичного функционала Системы или получения услуг, необходимых для
          реализации функционала Системы. 5.2. Пользователь вправе: 5.2.1.
          отказаться от информационной рассылки и(или) push-уведомлений
          рекламно-информационного характера, сообщив в службу поддержки
          Компании; 5.2.2. В одностороннем порядке отказаться от настоящего
          Соглашения, предварительно исполнив все свои обязательства по
          заключенным Сделкам. 6. СДЕЛКИ В СИСТЕМЕ 6.1. Сделки по выполнению
          Пользователем поручения Заказчика совершаются исключительно между
          указанными сторонами. 6.2. Заказчики формируют в Системе реестр
          предложений, включающий в себя: перечень требований к Исполнителям,
          перечень типовых поручений для Исполнителей, отвечающих установленным
          требованиям к ним; перечень адресов объектов и прилегающих к ним
          территорий, где необходимо исполнять поручения. 6.3. Пользователь
          изучает предложения Заказчиков о выполнении поручений и формируем ему
          оферту. 6.4. Оферта Пользователя Заказчику – это отклик на размещенное
          поручение, содержащий: 6.5. информацию о Пользователе, содержащуюся в
          Аккаунте; 6.5.1. описание поручения, которое Пользователь готов
          выполнить в интересах Заказчика; 6.5.2. сроки исполнения поручения в
          интересах Заказчика; 6.5.3. условие о вознаграждении за выполненное
          поручение в интересах Заказчика; 6.5.4. согласия Пользователя с
          обязательными документами, содержащимися в предложениях Заказчика;
          6.6. Оферта Пользователя Заказчику подписывается простой электронной
          подписью в соответствии с Соглашением об электронном взаимодействии и
          направляется посредством функционала Системы в адрес Заказчика на
          рассмотрение. 6.7. Компания не имеет возможности влиять на содержание
          оферты Пользователя, адресованной Заказчику, а также не имеет
          возможности влиять на Заказчика по вопросам рассмотрения оферты. 6.8.
          Заказчик, получивший оферту Пользователя, вправе принять ее или
          отклонить. Принятие оферты осуществляется посредством ее подписания
          простой электронной подписью в соответствии с Соглашением об
          электронном взаимодействии 6.9. В случае принятия Заказчиком оферты
          Пользователя, Сделка считается заключенной, о чем Пользователь
          извещается через функционал Системы. 7. РАСКРЫТИЕ ИНФОРМАЦИИ О СДЕЛКАХ
          7.1. Пользователь дает согласие Компании на обработку всей информации
          о заключенных с Заказчиками Сделках. 7.2. Пользователи дают согласие
          Компании сохранять информацию о Сделке и ее условиях, о денежных
          операциях, осуществленных в интересах Пользователей в целях исполнения
          Сделки. 7.3. Пользователи дают согласие Компании передавать информации
          о Сделке и ее условиях, о денежных операциях, осуществленных в
          интересах Пользователей в целях исполнения Сделки в Федеральную
          налоговую службу Российской Федерации, страховые компании, с которыми
          Пользователи планируют заключить договор страхования. 8. СОГЛАСИЕ НА
          ИСПОЛНЕНИЕ ОТ ТРЕТЬЕГО ЛИЦА 8.1. Пользователи выражают свое согласие с
          тем, что обязательства Заказчика по заключенным Сделкам могут быть
          выполнены третьими лицами, без нарушения условий Сделки прав
          пользователей. 9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ 9.1. Применимым
          законодательством к отношениям, возникшим в рамках настоящего
          Соглашения, является законодательство Российской Федерации. 9.2. В
          случае возникновения споров по вопросам исполнения настоящего
          Соглашения Стороны обязуется решить их путем переговоров, в случае не
          достижения согласия – в суде по месту нахождения Компании. 9.3.
          Недействительность или утрата силы части Соглашения не прекращает его
          действие в целом. 9.4. В одностороннем порядке изменять условия
          настоящего Соглашения, Правил Системы.
        </div>
      </Modal>
    );
  }

  return (
    <>
      {getIsServiceFee && (
        <CustomCheckBox
          onChange={ChangeIsServiceChargeAmount}
          checked={getIsServiceChargeAmount}
          classNameCheckBox={"check__input"}
          classNameFakeCheckBox={"check_box"}
        >
          <span
            onClick={(event: any) => {
              event.stopPropagation();
              setiIsVisModalServiceFeeWarning(
                (isVisModalServiceFeeWarning) => !isVisModalServiceFeeWarning
              );
            }}
          >
            {getServiceFeeText}({getServiceChargeAmount}₽)
          </span>
        </CustomCheckBox>
      )}

      <CustomCheckBox
        onChange={ChangeIsAgreeConditionPymentsCheckBox}
        checked={getIsAgreeConditionPymentsCheckBox}
        classNameCheckBox={"check__input"}
        classNameFakeCheckBox={"check_box"}
      >
        <span
          onClick={(event: any) => {
            event.stopPropagation();
            setisVisModalCondition(
              (isVisModalCondition) => !isVisModalCondition
            );
          }}
        >
          Согласен c условиями использования Политикой конфиденциальности и
          обработки персональных данных Гиберно
        </span>
      </CustomCheckBox>
    </>
  );
});

export default BLOCK_СONDITIONSORDER;
