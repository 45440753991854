// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
  position: fixed;
  background: rgba(101, 94, 94, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 6;
}

.popup-content {
  background: #fff;
  top: 35%;
  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);
  left: 0;
  padding: 9px 16px 16px;
  position: fixed;
  right: 0;
  height: 35%;
  z-index: 6;
  overflow: auto;
}

.popup-content__buttonShare {

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/PopupShare/PopupShare.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;EAClC,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,QAAQ;EACR,0CAA0C;EAC1C,OAAO;EACP,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,WAAW;EACX,UAAU;EACV,cAAc;AAChB;;AAEA;;EAEE,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB","sourcesContent":[".popup {\n  position: fixed;\n  background: rgba(101, 94, 94, 0.5);\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 6;\n}\n\n.popup-content {\n  background: #fff;\n  top: 35%;\n  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);\n  left: 0;\n  padding: 9px 16px 16px;\n  position: fixed;\n  right: 0;\n  height: 35%;\n  z-index: 6;\n  overflow: auto;\n}\n\n.popup-content__buttonShare {\n\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
