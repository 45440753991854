// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baseButton {
  border: none;
  border-radius: 41px;
  color: #343d5d;
  cursor: pointer;
  font-family: "Commissioner", sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 22px;
  outline: none;
  padding-bottom: 16px;
  padding-top: 16px;
  text-align: center;
  width: 100%;
}

.baseButton_blue {
  background: #6764ff;
  color: #fff;
}

.baseButton_gray {
  background: gray;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/components/BLOCK_WAYSPAY/BLOCK_WAYSPAY__BUTTON/BlockWaysPayButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,uCAAuC;EACvC,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".baseButton {\n  border: none;\n  border-radius: 41px;\n  color: #343d5d;\n  cursor: pointer;\n  font-family: \"Commissioner\", sans-serif;\n  font-size: 17px;\n  font-weight: 600;\n  letter-spacing: 0.03em;\n  line-height: 22px;\n  outline: none;\n  padding-bottom: 16px;\n  padding-top: 16px;\n  text-align: center;\n  width: 100%;\n}\n\n.baseButton_blue {\n  background: #6764ff;\n  color: #fff;\n}\n\n.baseButton_gray {\n  background: gray;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
