// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageOrder {

    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.OrderContent {

    padding: 16px 16px;

}

.wrapperBlock {

    background-color: #fff;
    border: 1px solid #e6e7eb;
    border-radius: 12px;
    list-style-type: none;
    padding: 12px;
    margin-bottom: 12px;

}`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/PageOrder.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;;IAEI,kBAAkB;;AAEtB;;AAEA;;IAEI,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;;AAEvB","sourcesContent":[".pageOrder {\n\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.OrderContent {\n\n    padding: 16px 16px;\n\n}\n\n.wrapperBlock {\n\n    background-color: #fff;\n    border: 1px solid #e6e7eb;\n    border-radius: 12px;\n    list-style-type: none;\n    padding: 12px;\n    margin-bottom: 12px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
