// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html {
    height: -webkit-fill-available;
  }
  
  body {
    margin: 0;
    font-family: "Commissioner", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    min-height: 100vh;
    /* WebKit [iOS Safari] fix: mobile viewport bug when 100vh is unaware of browser UI */
    min-height: -webkit-fill-available;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  #root {
    height: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/index.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;EAChC;;EAEA;IACE,SAAS;IACT;;gBAEY;IACZ,mCAAmC;IACnC,kCAAkC;IAClC,aAAa;IACb,iBAAiB;IACjB,qFAAqF;IACrF,kCAAkC;EACpC;;EAEA;IACE;eACW;EACb;;EAEA;;;IAGE,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd","sourcesContent":["\nhtml {\n    height: -webkit-fill-available;\n  }\n  \n  body {\n    margin: 0;\n    font-family: \"Commissioner\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n      \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n      sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    height: 100vh;\n    min-height: 100vh;\n    /* WebKit [iOS Safari] fix: mobile viewport bug when 100vh is unaware of browser UI */\n    min-height: -webkit-fill-available;\n  }\n  \n  code {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n      monospace;\n  }\n  \n  *,\n  *::before,\n  *::after {\n    box-sizing: border-box;\n  }\n  \n  #root {\n    height: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
