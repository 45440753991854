const getApiUrl = () => {
  let api_url: string = "";
  if (process.env.REACT_APP_GIBERNO_ENV === "prod") {
    // eslint-disable-next-line no-console
    console.log("prod");
    api_url = "https://api.giberno.ru";
  } else if (process.env.REACT_APP_GIBERNO_ENV === "dev") {
    // eslint-disable-next-line no-console
    console.log("dev");
    api_url = "https://dev.api.giberno.ru";
  } else {
    // eslint-disable-next-line no-console
    console.log("local");
    api_url = "";
  }

  return api_url;
};

export { getApiUrl };
