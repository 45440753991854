// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check {
    display: flex;
    justify-content: baseline;
    padding-left: 1.2em;
    color: #80869a;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  
  }
  .option {

    margin-bottom: 0.25em;
  }`, "",{"version":3,"sources":["webpack://./src/shared/components/CustomCheckBox/CustomCheckBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;;EAEpB;EACA;;IAEE,qBAAqB;EACvB","sourcesContent":[".check {\n    display: flex;\n    justify-content: baseline;\n    padding-left: 1.2em;\n    color: #80869a;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    margin-bottom: 8px;\n  \n  }\n  .option {\n\n    margin-bottom: 0.25em;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
