// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Block-SeparateOrder {

    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Block-SeparateOrder__SWITCHSeparatedOrder {

    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 48px;
    color: #494c55;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin-right: 10px;
}

@media (max-width: 315px) {
    .Block-SeparateOrder__SWITCHSeparatedOrder{
      padding: 0;
      align-items: center;
    }
  }`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/components/BLOCK_SEPARATEORDER/BlockSeparateOrder.css"],"names":[],"mappings":";AACA;;IAEI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;;IAEI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;MACE,UAAU;MACV,mBAAmB;IACrB;EACF","sourcesContent":["\n.Block-SeparateOrder {\n\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.Block-SeparateOrder__SWITCHSeparatedOrder {\n\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    min-height: 48px;\n    color: #494c55;\n    font-size: 17px;\n    font-weight: 600;\n    line-height: 22px;\n    margin-right: 10px;\n}\n\n@media (max-width: 315px) {\n    .Block-SeparateOrder__SWITCHSeparatedOrder{\n      padding: 0;\n      align-items: center;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
