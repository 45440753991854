// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Block-InputTips___BUTTONChangeTips {

    background: #f7f8fa;
    border: 1px solid #f7f8fa;
    border-radius: 6px;
    color: #80869a;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 4px;
}
.Block-InputTips___BUTTONChangeTips_active  {

    border-color: #6764ff;
    color: #010d35;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/components/BLOCK_TIPS/BLOCK_TIPS__BUTTON/ButtonChangeTips.css"],"names":[],"mappings":"AAAA;;IAEI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;AACA;;IAEI,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".Block-InputTips___BUTTONChangeTips {\n\n    background: #f7f8fa;\n    border: 1px solid #f7f8fa;\n    border-radius: 6px;\n    color: #80869a;\n    cursor: pointer;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 20px;\n    padding: 4px;\n}\n.Block-InputTips___BUTTONChangeTips_active  {\n\n    border-color: #6764ff;\n    color: #010d35;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
