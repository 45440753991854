// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Block-GeneralAmount {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* position: fixed;
  right: 0; */
  /* z-index: 1; */
  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);
  /* left: 0; */
  margin-bottom: 10px;
}
.GeneralAmount__eat {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 16px;
}
.Block-AllInfoAboutPay {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  color: #343d5d;
}
.Block-AllInfoAboutPay__separate_container,
  .Block-GeneralAmount__separate_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Block-AllInfoAboutPay__calcuatedAmount {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 6px;
}
.Block-GeneralAmount__LineDivider {
  background: #edeef1;
  height: 1px;
  margin-bottom: 15px;
  width: 100%;
}
.Block-GeneralAmount__result {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/PageOrder/components/BLOCK_GENERALAMOUNT/GeneralAmount.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB;aACW;EACX,gBAAgB;EAChB,0CAA0C;EAC1C,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,eAAe;EACf,cAAc;AAChB;AACA;;EAEE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".Block-GeneralAmount {\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n  /* position: fixed;\n  right: 0; */\n  /* z-index: 1; */\n  box-shadow: 1px 0 11px rgba(0, 0, 0, 0.09);\n  /* left: 0; */\n  margin-bottom: 10px;\n}\n.GeneralAmount__eat {\n  display: flex;\n  justify-content: space-between;\n  font-weight: 600;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 20px;\n  padding-bottom: 16px;\n}\n.Block-AllInfoAboutPay {\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 20px;\n  padding-bottom: 16px;\n  display: flex;\n  flex-wrap: wrap;\n  color: #343d5d;\n}\n.Block-AllInfoAboutPay__separate_container,\n  .Block-GeneralAmount__separate_container {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n.Block-AllInfoAboutPay__calcuatedAmount {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  font-weight: 600;\n  line-height: 25px;\n  margin-bottom: 6px;\n}\n.Block-GeneralAmount__LineDivider {\n  background: #edeef1;\n  height: 1px;\n  margin-bottom: 15px;\n  width: 100%;\n}\n.Block-GeneralAmount__result {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
